.successMsgContainerInner p {
  padding-right: 10px;
}

.action-dialog_icon .MuiIconButton-root:hover {
  background: none;
}

.mt-1 {
  margin-top: 1em !important;
}

.filterContainer {
  margin: 10px 0;
}

.filterContainer .MuiButton-containedPrimary {
  margin-left: 10px;
}
.warningIcon {
  position: relative;
  top: 5px;
  color: red;
}

.img-download {
  position: relative;
  margin: 0 3px;
}

.img-download > a {
  position: absolute;
  top: 0;
  right: 0;
}
.images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px !important;
  justify-content: center;
}
.img-hw {
  height: auto;
  width: 83px;
  cursor: pointer;
 
}
.img-blur {
  top: 0;
  position: absolute;
  background-color: #fff;
  height: 20px;
  right: 0;
  color: #536dfe;
  cursor: pointer;
}
.image-row {
  display: inherit;
  margin: 20px auto;
  height: auto;
}
.img-m {
  text-align: center !important;
  margin: auto;
  display: table;
}
.marginAuto {
  margin: 0 auto;
}
.supported_documents {
  text-align: center;
  font-size: 15px;  
}
.text-center {
  text-align: center;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.pdfAcceptance {
  display: flex;
}
.textRed {
  color: red;
}