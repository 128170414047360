.input_type{
    width: 100%;
    height: auto;
    text-align: center;
    padding: 10px;
}

.form_profile{
    box-shadow: 1px 3px 3px 1px rgba(0,0,0,0.12);
    background: white;
    width: 70%;
    padding: 40px;
    margin: auto;
    position: relative;
    top: 100px;
}
.profile_ok{
    background-color: #CCE5FF;
    width: 100%;
    height: auto;
}
.profile_Cancel{
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 600px) 
{
.form_profile{
    width: 100%;
    height: auto;
    
}
.comman_label{
   padding: 0px !important;
   margin: 10px 0px;
}
.profile_Cancel {
    margin: 20px 0px !important;
}
.profile_ok{
    margin-top: 20px !important;
}
}

.errMsgConatiner {
    color: black;
    border: 2px solid #e46c82;
    padding: 5px;
    font-size: 17px;
    text-align: center;
    background-color: #e0abab;
    margin: 5px auto 0;
}