.users_class{
    padding-top: 17px;
   
}
.user_button{
    text-align: end;
  padding-top: 17px;
   
}
.user_ok{
    background-color: #CCE5FF;
    width: 100%;
    height: auto;
}
.user_Cancel{
    width: 100%;
    height: auto;
}
.action{
    cursor: default;
   float: right;
   color: red;
   font-weight: bold;
}
.actions{
    cursor: pointer;
     color: green;
    font-weight: bold;
 }
 .craeteicon_user{
     cursor: pointer;
 }
 select {
text-align-last: center;}

.input_type_user{
    width: 100%;
    height: auto;
    text-align: center;
    padding: 10px;
}

/* ------------------request design-------------------- */
.request_hed{
    font-weight: bold;
    font-size: 20px;
    color: black;
}
.req_checkbox{
    padding: 0px !important;
}
.req_ok{
    width: 100%;
    height: auto;
    }

.req_cancel{
    width: 100%;
    height: auto;
    }

.can_req_class{
    font-size: 20px;
    font-weight: 600;
}    
.action_data{
    cursor: pointer;
}
.filter{
    width: 30%;
    text-align: center;
    float: right;
    padding: 10px 0px;
    margin-bottom: 29px;
    color: black;
    border: 2px solid rgb(135,184,206);
    background: rgb(177,221,240);
  
}
.filter_option{
    background: white;
}
.req_action_form{
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    height: auto !important;
}
.req_padding{
    padding:0px 10px;
}
.right_data{
    padding: 0px 0px 20px 10px;
   
}

.pass_word{
    padding: 20px 0px 0px 10px;
    display: block;
    width: 100%;
}
.pass_type{
    margin: 0px 0px 0 10px;
    width: 100%;
}

.list_key{
    list-style-type: none;
    display: flex;
    word-break: break-all;
}

.keycode_box{
    box-sizing: border-box;
    border: 1px solid;
    border-radius: 12px;
    width: 50%;
    margin: 30px 3px;
    padding:0px 10px;
}
.req_note{
    color: red;
    padding:0px 10px;
    text-align: justify;
}
.cancel_button{
    float: right !important;
    margin:10px !important;
    width: 100%;
    height: auto;
}
.ok_button{
    width: 100%;
    height: auto;
    margin: 0px 10px 0px 0px !important;
}
.key_copy{
    float: right;
    margin: 5px 0;
    cursor: pointer;
    background-color: rgba(83, 109, 254, 0.15);
    height: 45px;
    display: flex;
    font-size: 24px;
    min-width: 45px;
    align-items: center;
    border-radius: 45px;
    justify-content: center;
    color: rgb(83, 109, 254);
}
.key_copied {
    float: right;
    margin: 5px 0;
    cursor: pointer;
    background-color: rgba(83, 109, 254, 0.15);
    height: 45px;
    display: flex;
    font-size: 24px;
    min-width: 45px;
    align-items: center;
    border-radius: 45px;
    justify-content: center;
    color: #FF5C93;
}
.top_border{
    border-top: 1px solid;
    display: block;
    width: 65%;
    margin: 15px auto 0 auto;
}
.reason_type{
    width: 100%;
    margin: 0 10px;
    height: auto;
    padding: 10px;
}
.keycode_padd{
    margin: 25px 0px 25px 10px !important;
    padding: 0px !important;
    width: 100% !important;
    height: auto !important;
}
.key_type{
    width: 90%;
    height: auto;
    padding: 10px;
}


@media only screen and (max-width: 600px) {
.user_form{
    width: 100%;
    height: auto;
    margin: 10px;
}
.comman_label{
    padding: 0px !important;
    margin: 10px 0px;
 }
  .user_label{
    padding: 20px !important;
    
  }
  .user_label_country{
    padding: 24px 18px !important;
  }
  .user_label_dd{
    padding: 5px 16px !important;
  }
  .user_Cancel  {
    margin: 20px 0px !important;
    width: 94%;
}
.user_ok {
    margin-top: 20px !important;
    width: 94%;
} 
.input_type_user{
    width: 95% !important;
    text-align: center;
    padding: 10px;
}
.req_ok{
    margin-top: 20px !important;
    width: 94%;
}
.req_cancel{
    margin: 20px 0px !important;
    width: 94%;
}
}
.text-center {
    text-align: center !important;
}
.MuiAlert-standardSuccess {
    z-index: 999;
}