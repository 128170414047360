.collapse-dealer {
  cursor: pointer;
}
.delete {
  cursor: pointer !important;
  color: red;
  font-weight: bold;
}
.edit {
   font-weight: bold;
  cursor: pointer;
  color: blue;
}
.dealer_ok {
  cursor: pointer !important;
}
.button_ok {
  background-color: #cce5ff;
  width: 100%;
  height: auto;
}
.dealer_Cancel {
  width: 100%;
  height: auto;
}
.form_dealer {
  width: 100%;
  padding: 10px;
}
.type_label {
  margin-top: 10px;
}
.input_type {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 10px;
  font-family: Arial !important;
}
.buttons {
  padding: 0;
  margin: auto;
}
.dropdown_city_country {
  width: 100%;
  padding: 10px;
  color: rgb(74, 74, 74);
  text-align-last: center;
}
.dropdown_city_country_edit {
  width: 100%;
  padding: 10px;
  color: #000;
  text-align-last: center;
}
.downtbl_action {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.add_dealer_title {
  padding: 0px 0px 30px 0px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.MuiDialog-container {
  background: rgba(0, 0, 0, 0.5);
}
.form_dealer {
  padding-bottom: 35px !important;
}
.comman_label {
  margin-top: 10px;
  font-weight: bold;
  font-size: 15px;
}
.type_label {
  font-weight: bold;
  font-size: 15px;
}
.top_modal_cnt {
  position: relative;
}
.cancel_icon {
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 9999999;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.action_dialog_title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  margin-bottom: 5px;
  padding: 10px 5px;
  text-align: center;
}
.action-dialog_top {
  position: relative;
}
.action-dialog_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.action_data {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (max-width: 600px) {
  .dealer_table {
    width: 100%;
    height: auto;
  }
  .form_dealer {
    width: 100%;
    height: auto;
  }
  .label_responsive {
    padding: 0 18px !important;
    margin: 0px 0px;
  }
  .label_responsive_country {
    padding: 0px 18px !important;
  }
  .label_responsive_dd {
    padding: 5px 16px !important;
  }

  .dealer_Cancel {
    margin: 20px 0px !important;
    width: 94%;
  }
  .dealer_ok {
    margin-top: 20px !important;
    width: 94%;
  }
}
